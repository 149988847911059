import {alertService} from "@/services/alert/alert.service";
import {resetPasswordService} from "@/services/auth/reset-password/reset-password.service";

export default {
    data: () => {
        return {
            data: {},
            error: {},
            success: {},
            config: {},
            disable: false,
        }
    },
    async mounted() {


    },
    methods: {


        async reset$() {
            this.error = {}
            this.success = {}
            await alertService.loading();
            
            await resetPasswordService.reset(this.data).then((response) => {
                alertService.close();
                this.disable = true
                this.success = response
            }).catch((error) => {
                this.error = error;
                alertService.close();
            });

        },
    }

}
