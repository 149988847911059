import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class ResetPasswordService {
    reset(data) {
        promise =  api.client.post(config.end_point.auth.reset_password, data);
        return promise;
    }
}

export const resetPasswordService = new ResetPasswordService();
